<script setup lang="ts">
import { type IReview } from "~/types/base";

const props = defineProps<{
  review: IReview
}>()

const config = useRuntimeConfig()

const ImageSrc = computed(() => config.public.imageProvider === 'ipx' ? props.review.image_url : props.review.image_path)
</script>

<template>
  <nuxt-link
      :to="`/book-reviews/${review.slug}`"
      :title="review.title"
      class="group review-preview-item w-full flex flex-col bg-white rounded-xl overflow-hidden dark:border-zinc-700 dark:bg-zinc-800"
  >
    <div class="relative overflow-hidden h-[180px] bg-gray-200 dark:bg-zinc-600 max-lg:h-[191px]">
      <nuxt-img
          :provider="config.public.imageProvider"
          :src="ImageSrc"
          loading="lazy"
          :alt="review.title"
          :quality="80"
          height="180"
          format="webp"
          class="w-full h-full object-cover transition-transform duration-1000 transform group-hover:scale-110"
      />
    </div>
    <div class="p-4 pt-2 flex flex-col gap-2">
      <p v-if="review?.genres && review.genres.length > 0" class="text-primary uppercase">{{ review.genres[0].name }}</p>
      <h3 class="font-xl leading-5 line-clamp-2 max-lg:line-clamp-3 text-gray-950 font-semibold dark:text-gray-200">{{ review.title }}</h3>
    </div>
  </nuxt-link>
</template>

<style scoped>

</style>